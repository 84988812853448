
import membersApi from "@/core/services/MembersApi";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "qr-code",
    components: {},
    data() {
        return {
            loading: true,
            validCard: false,
            member: {} as any,
            memberDues: [] as any,
            lastCdrDue: "",
            lastOmgdDue: "",
        };
    },
    setup() {
        return { route: useRoute(), router: useRouter() };
    },
    mounted() {
        if (this.route.params.member_id) {
            this.getMember(this.route.params.member_id as string);
        }
    },
    methods: {
        getMember(id: string) {
            membersApi
                .getShortenMember(id)
                .then((response: any) => {
                    this.validCard = true;
                    this.member = response.data.member;
                    this.memberDues = response.data.member.dues;
                    this.setLastDues();
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.validCard = false;
                });
        },
        signIn() {
            localStorage.setItem("member_id", this.route.params.member_id as string);
            localStorage.setItem("perm_pos", "5");
            this.router.push({ name: "sign-in" });
        },
        setLastDues() {
            this.memberDues.forEach((element: any) => {
                if (element.perimeter === "CDR") {
                    this.lastCdrDue = element.date;
                } else if (element.perimeter === "OMGD") {
                    this.lastOmgdDue = element.date;
                }
            });
        },
    },
});
